import { put, takeLatest, call, all } from 'redux-saga/effects';
import { getEmailByCode, sendInfoRegisterUser } from './services/services';
import { REGISTER_ACTIONS } from './constants/actions';
import { receiveResponseRegisterUser, receiveResponseValidateUser } from './redux/actions/register';

// AUTH


function* getEmailByOtp(action) {
	try {
		const response = yield call(getEmailByCode, action.code);
		console.log(response)
		yield put(receiveResponseValidateUser(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* getInfoByOtpWatcher(action) {
	yield takeLatest(REGISTER_ACTIONS.REGISTER_ACTIONS_GET_EMAIL_BY_CODE, getEmailByOtp)
}
// registro de usuario nuevo
function* putRegisterUser(action) {
	try {
		// console.log(action)
		const aux = { ...action.user };
		aux.email = action.email
		console.log(aux)
		console.log(action.code)
		const response = yield call(sendInfoRegisterUser, aux, action.code);
		console.log(response)
		yield put(receiveResponseRegisterUser(response))
	}
	catch (e) {
		console.log(e)
	}
}

function* putRegisterUserWatcher(action) {
	yield takeLatest(REGISTER_ACTIONS.REGISTER_ACTIONS_REGISTER_USER, putRegisterUser)
}


export default function* sagas() {
	yield all([
		getInfoByOtpWatcher(),
		putRegisterUserWatcher()
	]) 
}
