import React, { Suspense }                                  from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect }                                          from 'react-redux';

import { checkUserToken } from './main/auth/helpers';

const LoginView = React.lazy(() => import('./main/auth/auth.route'));

checkUserToken();
const Root: React.FC<any> = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Router>
                <Switch>
                    <Route component={LoginView} path=''/>
                </Switch>
            </Router>
        </Suspense>
    );
};

export default connect((state) => ({
    user: state.register.user,
}))(Root);
