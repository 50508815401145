import { POST_REGISTER_USER, GET_INFO_USER } from '../constants/routes';

export const sendInfoRegisterUser = async (user, code) => {
	try {
			const options = { 
				method: 'PUT',
				 headers: {
		 		 	'Content-type': 'application/json'
		 		 },
				body: JSON.stringify(user),
				mode: 'cors'
			};
			const response =  await fetch(POST_REGISTER_USER(code), options)
			return response.json();
	}
	catch (e) {
		console.log('error:', e)
	}
}

export const getEmailByCode = async (otp) => {
	try {
		 console.log(otp)
		 	const options = {
		 		method: "GET",
		 		mode: 'cors'
		 	}

		 	const response =  await fetch (GET_INFO_USER(otp), options);
		 	return response.json();
	}
	catch (e) {
		console.log('error:', e)
	}
}
