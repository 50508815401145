import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';

import register from './redux/reducers/register';

const sagaMiddleware = createSagaMiddleware();
export default createStore(combineReducers({
    register,
}), applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);
